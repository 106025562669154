main.login-wrapper {
	width: 100%;
	height: 100vh;
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	.logo {
		background: url(../assets/images/login-bg.png) center center no-repeat;
		background-size: cover !important;
		display: flex;
		align-items: center;
		justify-content: center;
		
		@media (max-width: 768px) {
			display: none;
		}

	}

	.not-registered {
		border-top: 1px solid #e5e5e5;
		margin-top: 20px;
		text-align: center;
		padding-top: 20px;

		a {
			color: $c1;
			font-weight: bold;
		}
	}

	.form {
		background-image: linear-gradient(196deg, #73dae5 0%, #3fb3c7 96%);
		display: flex;
		align-items: center;
		justify-content: center;

		.form-wrapper {
			width: 100%;
			max-width: 500px;
			background: #ffffff;
			box-shadow: 0 9px 32px 0 rgba(0, 0, 0, 0.14);
			border-radius: 18px;

			.form-inset {
				padding: 3rem;

				h2 {
					color: $c1;
					font-weight: bold;
				}

				span {
					margin-bottom: 20px;
					display: inline-block;
				}
			}

			.recatpcha {
				margin-bottom: 0px;

				> div {
					transform: scale(0.8);
					transform-origin: 0 0;
				}
			}

			.form-options {
				display: flex;
				justify-content: space-between;

				.check {
					display: flex;
					align-items: center;

					input {
						margin-right: 10px;
						appearance: none;
						width: 20px;
						height: 20px;
						border: 2px solid #e5e5e5;
						border-radius: 5px;
						cursor: pointer;

						&:checked {
							background: url("data:image/svg+xml,%0A%3Csvg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-22.000000, -29.000000)' fill='%23216F75'%3E%3Crect id='Rectangle' x='22' y='29' width='14' height='14' rx='4'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
								center center no-repeat;
							background-size: 12px 12px;
							border: 2px solid $c1;
						}
					}
				}

				button {
					width: auto;
					background-image: linear-gradient(194deg, #5ec0c6 0%, #308c94 100%);
					border-radius: 9px;
					box-shadow: none;
					font-family: $font;
					text-transform: uppercase;
				}
			}
		}
	}

	@media (max-width: 768px) {
		background-color: #3fb3c7;
		height: auto;
		display: inherit;
	}
}

.form-group {
	display: flex;
	flex-direction: column;
	label {
		color: $c1;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 0.8rem;
	}

	input, select {
		border: 2px solid #e5e5e5;
		border-radius: 10px;
		padding: 1rem;
	}
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
	background: $c1 !important;
}

div:where(.swal2-container) {
	font-family: $font;
}

main.loading {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(
		0deg,
		rgba(255, 255, 255, 1) 57%,
		rgba(203, 236, 238, 1) 100%
	);
	flex-direction: column;

	.loading-options {
		display: flex;
		flex-direction: column;
		margin-top: 40px;

		h2 {
			color: $c1;
			font-weight: bold;
		}

		button {
			margin-top: 20px;
			background: $c1;
			font-family: $font;
			@include button;
			margin: 0 auto;
			margin-top: 20px;
		}
	}
}

main.payment-not-ready {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100vh;
	background: linear-gradient(
		0deg,
		rgba(255, 255, 255, 1) 57%,
		rgba(203, 236, 238, 1) 100%
	);

	.inset {
		width: 100%;
		max-width: 500px;
		text-align: center;

		.images {
			display: flex;
			margin-bottom: 60px;

			img {
				max-width: 100%;
				height: auto;
				object-fit: cover;
				&:nth-child(1) {
					transform: scale(0.8);
					z-index: 1;
					border-radius: 10px;
					margin-left: -20%;
				}
				&:nth-child(2) {
					margin-left: -50%;
					z-index: 2;
					border-radius: 10px;
				}
			}
		}

		.loading-inline {
			margin-top: 20px;
		}

		h2 {
			color: $c1;
			font-weight: bold;
		}

		button {
			margin-top: 20px;
			background: $c1;
			font-family: $font;
			@include button;
			margin: 0 auto;
			margin-top: 20px;
		}
	}

	.checked-signup-success {
		svg {
			max-width: 150px;
    	margin-bottom: 20px;
		}
	}

}

.loading-view {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	&.auto {
		height: auto;
		padding: 10rem 0;
	}
}
