.frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5%;
    height: 80vh;
    text-align: center;
}

.button {
    margin-top: 50px;
}

.noSession{
    margin-top: 100px;
}

.centerv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.centerh {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rednext {
    background-color: rgb(220,0,0);
    color: rgb(255,255,255);
}