main.user-page {
	margin-top: 150px;

	.user-profile-form {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 2rem;
		margin-top: 50px;
		margin-bottom: 50px;

		@media all and (max-width: 999px) {
			display: flex;
			flex-direction: column;
		}

		.col {
			margin: 0;
			padding: 0;

			h2 {
				color: $c1;
				font-size: 1.4rem;
				font-weight: bold;
				margin-bottom: 20px;
			}
		}

		.action {
			grid-column: 1/3;
			text-align: right;

			button {
				float: right;
				width: auto;
				@include button;

				@media all and (max-width: 999px) {
					width: 100% !important;
				}
			}
		}
	}
}

.subscription-view {
	margin-bottom: 50px;
	.subscription-status {
		display: flex;
		margin-top: 40px;
		border: 1px solid #e5e5e5;
		border-radius: 10px;

		.col {
			padding: 15px;
			border-right: 1px solid #e5e5e5;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex-grow: 1;
			flex-basis: unset;

			.cancel {
				background: rgba(#e94738, 0.1);
				color: #e94738;
				box-shadow: none;
				border: 2px solid rgba(#e94738, 0.1);
				font-family: $font;
				white-space: nowrap;
				margin-right: 10px;

				&:hover {
					background: #e94738;
					color: white;
				}
			}

			.action {
				@include button;
				width: 100% !important;
				max-width: 100%;
				font-size: 1rem;
			}

			.button {
				font-size: 1rem;
				white-space: nowrap;
				min-width: none;
				padding: 15px 30px !important;
			}

			&:last-child {
				border-right: 0;
			}

			.subscription-options {
				display: flex;
				align-items: center;

				a {
					margin-right: 20px;
					border: 2px solid #e5e5e5;
					padding: 15px;
					white-space: nowrap;
					border-radius: 10px;
					color: $c1;

					&:hover {
						text-decoration: none;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	.payments {
		margin-top: 40px;

		h2 {
			color: $c1;
			font-size: 1.6rem;
			font-weight: bold;
			margin-bottom: 20px;
		}

		table {
			width: 100%;

			thead {
				th {
					background: #f9f9f9;
					padding: 10px;
					border-bottom: 2px solid #e5e5e5;
				}
			}

			tbody {
				td {
					border-bottom: 1px solid #e5e5e5;
					padding: 10px;
				}
			}
		}
	}
}

.badge {
	text-transform: uppercase;
	width: auto;
	font-size: 0.9rem;
	margin-top: 10px;
	&.success {
		border: 2px solid rgba(#1de164, 0.2);
		color: #1de164;
		background: rgba(#1de164, 0.1);
	}
}

.tests-listing {
	margin: 5rem 0;
	table {
		width: 100%;

		thead {
			th {
				background: #f9f9f9;
				border-bottom: 2px solid #e5e5e5;
				padding: 15px;
			}
		}

		tbody {
			td {
				padding: 15px;

				.btn-view {
					background: rgba($c2, 0.1);
					color: $c2;
					font-weight: bold;
					padding: 7px 10px;
					border-radius: 89px;
				}
			}
		}
	}
}

.modal-view {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(white, 0.9);
	backdrop-filter: blur(6px);
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;

	.modal-inset {
		width: 100%;
		max-width: 600px;
		position: relative;

		.close {
			@include button;
			font-weight: normal;
			width: 40px !important;
			height: 40px !important;
			padding: 0 !important;
			position: absolute;
			right: 10px;
			top: 10px;
			opacity: 1;
		}
	}
}

.thank-you-page.test-view {
	margin: 0;

	.user-info {
		display: flex;
		flex-wrap: wrap;

		span {
			margin-bottom: 20px;
			flex: 50%;
		}
	}
	h1 {
		font-size: 1.8rem !important;
	}
}



.subscription-view-notice {
	background: rgba($c2, .05);
	border: 2px solid rgba($c2, .1);
	min-height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 10px;
	font-family: $font;

	h2 {
		color: $c2;
		font-family: $font;
	}

	button {
		@include button;
	}
}


.no-tests {
	background: rgba($c2, .05);
	border: 2px solid rgba($c2, .1);
	min-height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 10px;
	font-family: $font;

	h2 {
		color: $c2;
		font-family: $font;
	}

	a {
		@include button;
		margin-top: 20px;
	}
}

.page-view {
	margin-top: 150px;
	line-height: 1.4;
	margin-bottom: 40px;
	font-size: 1rem;

	.text-content {
		font-size: 1rem;
	}

	a {
		color: $c2;
		font-weight: bold;
	}

	h1 {
		margin-bottom: 40px;
	}

	h4 {
		margin-bottom: 20px;
		font-size: 1.2rem;
		font-weight: bold;
		color: $c1;
	}

	h5 {
		font-size: 1rem;
		font-weight: bold;
	}

	ol {
		li {
			list-style: auto;
			margin-left: 20px;
			padding-left: 10px;
		}
	}
}