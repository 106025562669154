@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;700&display=swap");
@import "normalize";

// Vars

$font: "Outfit", sans-serif;
$c1: #216e75;
$c2: #0db2e3;
$c3: #7f7f7f;

$text: #455155;

$g1: linear-gradient(197deg, #40a7ae 0%, #1e6e76 100%);

// Main
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body,
main {
	font-family: $font;
	color: $text;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.landing-page {
	display: grid;
	place-items: center;
	text-align: center;

	.main-article {
		display: grid;
		background: $g1;
		width: 100%;
		height: 850px;
		place-items: center;
		text-align: center;
		padding: 50px;
		color: white;
		gap: 15px;

		h1 {
			font: bold 4rem $font;
		}

		img {
			width: 50%;
			border: 6px solid white;
			border-radius: 10px;
		}

		button, .button-btn {
			background: white;
			color: $c1;
			width: fit-content;
			padding: 18px 30px 18px 30px;
			border-radius: 40px;
			text-align: center;
			font: bold 20px $font;
		}

	}

	.info-article {
		padding: 20px 0px 20px 0px;

		.card-wrapper {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			padding: 50px 0px 50px 0px;
			gap: 30px;
		}

		.card-image {
			width: 150px;
			height: 150px;
			background-size: cover;
			background-position: center;
		}

		.article-card {
			display: grid;
			max-width: 280px;

			h5 {
				margin-top: 14px;
			}
		}

		.card-image-wrapper {
			padding: 35px 50px;
			justify-items: center;
			align-items: center;
			border-radius: 10px;
			background-color: #F3F4F6;
			border: none;
		}

		.text-card-wrapper {
			padding: 0px 10px 0px 10px;

			h5 {
				font-weight: bold;
			}

			* {
				color: black
			}
		}

		
	}

	.benefits-article {
		background-color: #F3F4F6;
		display: grid;
		width: 100%;
		place-items: center;
		text-align: center;
		padding: 70px;
		gap: 60px;
		
		text-align: start;

		.list-wrapper {
			display: grid;
			font-size: 1.1rem;
			gap: 15px;
			font-weight: 500;
		}

		button, .button-btn {
			background:$c1;
			color: white;
			width: fit-content;
			padding: 18px 30px 18px 30px;
			border-radius: 40px;
			text-align: center;
			font: bold 20px $font;
		}
	}

	h2 {
		font-weight: bold;
		color: black;
		font-size: 40px;
	}

	p {
		font-weight: 500;
	}

}

body {
	padding-right: 0 !important;
}

@mixin button {
	background: $g1;
	padding: 15px 80px !important;
	width: auto !important;
	height: auto !important;
	color: white !important;
	font-size: 1.3rem;
	text-decoration: none;
	border-radius: 5px;
	border: 0px !important;
	font-family: $font;
	margin: 0;
	cursor: pointer;
	opacity: 1;

	&:hover {}

	&:link {
		opacity: 1 !important;
	}

	&:visited {
		opacity: 1 !important;
	}

	&:active {
		opacity: 0.6 !important;
	}
}

.content {
	width: 100%;
	max-width: 1340px;
	margin: 0 auto;
	padding: 0px 10px;
}


@keyframes shine {
	to {
		background-position-x: -200%;
	}
}


header {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	background: url("../assets/images/header-bg.png") left top repeat-x;
	height: 240px;
	padding-top: 40px;
	z-index: 100;
	font-family: $font;

	.user {
		.skelleton {
			width: 80px;
			height: 30px;
			background: linear-gradient(110deg, #f1f1f1 8%, #e5e5e5 18%, #f1f1f1 33%);
			background-size: 200% 100%;
			animation: 1.5s shine linear infinite;
			border-radius: 20px;
		}

		.login-button {
			@include button;
			font-size: 1rem;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 0.8rem;
			padding: 8px 20px !important;
			border-radius: 100px;
		}

		.user-drop {
			position: relative;

			.sub-menu {
				position: absolute;
				right: 0;
				top: 100%;
				background: white;
				width: 180px;
				display: flex;
				flex-direction: column;
				border-radius: 10px;
				padding: 5px;
				margin-top: 0px;
				box-shadow: 0px 5px 20px 0px rgba($c1, 0.4);
				visibility: hidden;
				opacity: 0;
				-webkit-transition: all 180ms ease-in;
				-moz-transition: all 180ms ease-in;
				-o-transition: all 180ms ease-in;
				transition: all 180ms ease-in;

				&:after {
					position: absolute;
					width: 0;
					height: 0;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;

					border-bottom: 5px solid white;
					content: "";
					right: 10px;
					top: -5px;
				}

				&:before {
					content: "";
					position: absolute;
					width: 100%;
					height: 20px;
					top: 0;
					left: 0;
					margin-top: -20px;
				}

				a {
					display: block;
					padding: 10px 15px;
					color: $c2;
					border-radius: 10px;

					&:hover {
						text-decoration: none;
						background: rgba($c2, 0.1);
					}
				}
			}

			&:hover {
				cursor: pointer;

				.sub-menu {
					opacity: 1;
					visibility: visible;
					margin-top: 10px;
				}
			}
		}
	}

	@media (max-width: 999px) {
		.user {
			display: none;
		}
	}


	.languages {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		margin-top: -40px;

		button {
			background: none;
			box-shadow: none;
			color: $text;
			padding: 0;
			font-size: 0.7rem;
			padding: 10px;

			&.active {
				background: $c1;
				color: white;
				border-radius: 0 0 10px 10px;
			}
		}
	}

	@media all and (max-width: 999px) {
		position: fixed;
		left: 0;
		top: 0;
		height: 60px;
		background: rgba(white, 0.9);
		backdrop-filter: blur(4px);
		-webkit-backdrop-filter: blur(4px);
		padding-top: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		@media all and (max-width: 999px) {
			justify-content: center;
		}

		nav {
			text-transform: uppercase;

			@media all and (max-width: 999px) {
				display: none;
			}

			a {
				color: $c1;
				text-decoration: none;
				font-weight: 500;
				font-size: 0.9rem;
				margin-left: 40px;
				padding-left: 40px;
				border-left: 1px solid #ccc;

				&:hover {
					color: $c2;
				}

				&:first-child {
					border-left: 0;
					margin-left: 0;
					padding: 0;
				}
			}
		}
	}

	.validate-email-msg {
		top: 0;
		background: #fff;
		padding: 10px 0 1px;
		text-align: center;
		font-size: 0.8rem;
		color: #e31b1b;
		margin-top: 5px;
	}

}

.container {
	width: 100% !important;
	max-width: 100% !important;
	padding: 0 !important;
}

main {
	font-family: $font;

	button.button, .button-btn {
		@include button;
	}

	.content {
		width: 100%;
	}

	section.hero {
		width: 100%;
		position: relative;

		.info {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			display: flex;
			align-items: center;

			@media all and (max-width: 999px) {
				padding: 40px;
				align-items: flex-end;
			}

			.info-inset {
				width: 100%;
				max-width: 600px;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;

				@media all and (max-width: 999px) {
					background: rgba(white, 0.9);
					backdrop-filter: blur(4px);
					-webkit-backdrop-filter: blur(4px);
					max-width: 100%;
					padding: 40px;
				}

				h1 {
					color: $c1;
					font-size: 4rem;
					font-weight: 500;
					margin: 0;

					@media all and (max-width: 999px) {
						font-size: 3rem;
					}

					@media (max-width: 768px) {
						font-size: 2.6rem;
					}
				}

				h3 {
					font-weight: normal;
					font-size: 1.2rem;
					margin: 0;
					padding: 0 20px;
					color: #333c3f;

					@media all and (max-width: 999px) {
						font-size: 1.2rem;
					}

					@media (max-width: 768px) {
						font-size: 1rem;
						margin-top: 20px;
						padding: 0;
					}
				}

				.button {
					padding: 15px 100px;
					display: inline-block;
					// margin-top: 30px;

					@media all and (max-width: 999px) {
						width: 100%;
						padding: 15px 65px !important;
					}
				}

				img {
					max-width: 30%;
					height: auto;
					margin-top: 20px;

					@media all and (max-width: 999px) {
						max-width: 50%;
					}
				}

				.wrapper-start-signup {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 30px;

					span {
						color: $c1;
						font-size: 1.2rem;
						margin: 0 10px;
					}

					a {
						color: $c1;
						text-decoration: none;
						font-weight: bold;
						font-size: 1.2rem;
						margin: 0 10px;

						&:hover {
							color: $c2;
						}
					}
				}
			}
		}

		img {
			width: 100%;
			height: 950px;
			object-fit: cover;

			@media all and (max-width: 999px) {
				height: 600px;
			}
		}
	}

	section.start {
		background: #ffffff;
		box-shadow: 0 2px 74px 0 rgba(30, 110, 118, 0.3);
		border-radius: 8px;
		padding: 40px;
		display: flex;
		position: relative;
		margin-top: -40px;

		@media all and (max-width: 999px) {
			flex-direction: column;
		}

		.image {
			width: 45%;

			@media all and (max-width: 999px) {
				width: 100%;
			}

			img {
				max-width: 100%;
				height: auto;
			}
		}

		.info {
			flex: 1;
			margin-left: 50px;

			@media all and (max-width: 999px) {
				margin-left: 0px;
			}
		}
	}

	section.simple {
		padding: 40px;
		display: flex;
		position: relative;
		align-items: center;

		@media all and (max-width: 999px) {
			flex-direction: column-reverse;
		}

		.images {
			width: 45%;

			@media all and (max-width: 999px) {
				width: 100%;
			}

			img {
				max-width: 100%;
				height: auto;
			}
		}

		.info {
			flex: 1;
			margin-right: 50px;

			@media all and (max-width: 999px) {
				margin-left: 0px;
			}
		}
	}

	section.prescription {
		width: 100%;
		background: $g1;
		padding-top: 50px;
		margin-bottom: 150px;

		@media all and (max-width: 999px) {
			margin-bottom: 0;
		}

		.content {
			@media all and (max-width: 999px) {
				padding: 20px;
			}
			> h2 {
				text-align: center;
				font-size: 2.5rem;
				color: white;
				margin-bottom: 40px;
			}

			.list {
				margin: 0 auto;
				max-width: 1000px;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 50px;

				@media all and (max-width: 999px) {
					display: flex;
					flex-direction: column;
				}

				.item {
					background: white;
					background: #ffffff;
					box-shadow: 0 2px 74px 0 rgba(30, 110, 118, 0.3);
					border-radius: 8px;
					position: relative;
					margin-bottom: -60px;

					@media all and (max-width: 999px) {
						margin: 0;
					}

					.image {
						img {
							width: 100%;
							height: auto;
							border-radius: 10px;
						}
					}

					.info {
						padding: 40px;
						padding-top: 20px;
						display: flex;
						flex-direction: column;

						h4 {
							color: $c1;
							font-size: 1.3rem;
							margin: 0;
							margin-bottom: 10px;
						}

						span {
							font-size: 1.3rem;
							margin-bottom: 15px;
						}

						.button {
							text-align: center;
						}
					}
				}
			}
		}
	}

	section.faq {
		max-width: 1200px;
		margin: 0 auto;
		margin-bottom: 50px;
		display: flex;

		@media all and (max-width: 999px) {
			flex-direction: column;
		}

		.info {
			width: 40%;
			text-align: right;

			@media all and (max-width: 999px) {
				width: 100%;
				text-align: center;
				padding: 20px;
			}

			h2 {
				font-size: 3rem;
				color: $c1;
			}

			span {
				line-height: 1.4;
				font-size: 1.2rem;
			}
		}

		.list {
			flex: 1;
			margin-left: 60px;

			@media all and (max-width: 999px) {
				margin-left: 0px;
			}

			.item {
				border-bottom: 1px solid #e5e5e5;
				line-height: 1.4;
				padding: 30px 0px;

				&:last-child {
					border-bottom: 0;
				}

				h4 {
					font-size: 1.4rem;
				}
			}
		}
	}
}

footer {
	border-top: 1px solid #e5e5e5;
	padding: 30px 0px;
	font-family: $font;

	.content {
		display: flex;
		align-items: center;

		@media all and (max-width: 999px) {
			flex-direction: column;
		}

		.logo {
			flex: 1;

			@media all and (max-width: 999px) {
				padding: 40px 0px;
			}
		}

		nav {
			a {
				color: $text;
				text-decoration: none;
				margin-right: 20px;

				&:hover {
					color: $c1;
				}
			}
		}

		.options {
			display: flex;
			align-items: center;

			@media all and (max-width: 999px) {
				margin-top: 20px;
				margin-bottom: 20px;
			}

			span {
				opacity: 0.5;
			}

			.social {
				display: flex;

				a {
					margin-left: 20px;
				}
			}
		}
	}
}

.text-content {
	line-height: 1.6;
	font-size: 1.1rem;

	h2 {
		color: $c1;
		font-size: 2.5rem;
	}

	p {
		margin-bottom: 20px;
	}
}

@import "eye-test";

.end-test {
	min-height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 200px;
	font-family: $font;
}



@import "login";
@import "user-pages";


.responsive-table {
	width: 100%;

	tr {
    border-radius: 3px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .table-header {
    background-color: #95A5A6;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
  }
  .col1 {
    flex-basis: 10%;
  }
  .col2 {
    flex-basis: 10%;
  }
  .col3 {
    flex-basis: 25%;
  }
  .col4 {
    flex-basis: 25%;
  }
  
  @media all and (max-width: 767px) {
    .table-header {
      display: none;
    }
    .table-row{
      
    }
    li {
      display: block;
    }
    .col {
      
      flex-basis: 100%;

    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
}